import ProjectService from "../services/projectService";
import { useEffect, useState } from "react";
import { useAuth } from "../providers/authProvider";

const HomeHook = () => {
  const [data, setData] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    getProyectos();
  }, []);

  const getProyectos = () => {
    const user = auth.user;
    if (user) {
      ProjectService.getAllProjects(user.id)
        .then((resp) => {
          let tmp = [];
          resp.data.forEach((item) => {
            let contrato = {
              id: item.id,
              contrato: item.name,
              cliente: item.partner_id[1],
              inicio: "En Curso",
              fin: "En curso",
              actualizado: item.__last_update,
            };
            tmp.push(contrato);
          });

          setData(tmp);
        })
        .catch((err) => {});
    }
  };

  return {
    data,
  };
};

export default HomeHook;
