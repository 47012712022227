import { useRoutes } from "react-router-dom";
import Login from "../pages/login";
import Index from "../pages/index";
import Contrato from "../pages/contrato";

const Router = () => {
  const routes = [
    { path: "/login", Component: Login },
    { path: "/contrato/:id", Component: Contrato },
    { path: "/", Component: Index },
  ];

  return useRoutes(routes);
};

export default Router;