import { HiOutlineX } from "react-icons/hi";

export const Modal = ({ children, title, width = "500", setOpenModal }) => {
  return (
    <>
      <div className="bg-neutral-500-custom left-0 top-0 fixed size-full flex items-center justify-center z-100 flex-col">
        <div
          style={{ width: `${width}px` }}
          className="bg-white rounded-lg h-auto min-h-32 p-6"
        >
          <div className="modalHeader flex flex-grid justify-between items-center">
            <div>
              <span className="text-gray-600 text-base w-full">{title}</span>
            </div>
            <div>
              <span
                className="text-gray-500 hover:text-red-500 hover:font-bold cursor-pointer"
                onClick={() => setOpenModal(false)}
              >
                <HiOutlineX />
              </span>
            </div>
          </div>
          <div className="modalContent mt-4">{children}</div>
        </div>
      </div>
    </>
  );
};
