import React from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/layout";
import AuthProvider from "./providers/authProvider";
import Router from "./router/router";
import Store from "./redux/store";
import { Provider } from "react-redux";

export default function App() {
  return (
    <main className="body-aigx container-fluid">
      <Provider store={Store}>
        <BrowserRouter>
          <AuthProvider>
            <Layout>
              <Router />
            </Layout>
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </main>
  );
}
