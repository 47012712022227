import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { RiLogoutCircleLine } from "react-icons/ri";
import LanguageSelect from "./languageSelect";
import { Link } from "react-router-dom";
import { useAuth } from "../providers/authProvider";

export const Navbar = () => {
  const router = useNavigate();
  const [avatar, setAvatar] = useState(null);
  const auth = useAuth();
  useEffect(() => {
    
    if (!auth?.user) {
      router("/login");
    } else {
      const miAvatar = getAvatar(auth.user);
      setAvatar(miAvatar);
    }
  }, [avatar]);

  const getAvatar = (dataUser) => {
    const nickName = dataUser.name;
    const nombres = nickName.split(" ");
    if (nombres.length > 1) {
      return nombres
        .slice(0, 2)
        .map((nombre) => nombre.charAt(0))
        .join("");
    } else {
      return nombres
        .slice(0, 3)
        .map((nombre) => nombre.charAt(0))
        .join("");
    }
  };

  const logout = () => {
    setAvatar(null);
    auth.logOut();
  };
  return (
    <>
      <div className="w-full min-h-[60px] bg-white px-4 py-2 flex items-center justify-between">
        {auth?.user && (
          <>
            <div className="ml-12">
              <Link to={`/`} prefetch="true">
                <img src="/logo-aigx.png" alt="Aigx" width={60} height={60} />
              </Link>
            </div>
            <div className="flex flex-grid items-center space-x-4">
              <div className="min-w-[140px]">
                {/* <LanguageSelect /> */}
              </div>
              <button
                className="text-xl text-gray-400 hover:text-gray-700 transition-colors"
                onClick={(event) => logout()}
              >
                <RiLogoutCircleLine />
              </button>
              <span>{auth.user.name}</span>
              {avatar && (
                <div className="uppercase bg-blue-500 text-white size-9 flex items-center justify-center rounded-full">
                  {avatar}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
