import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import base64 from "base-64";
import * as Yup from "yup";
import Toaster from "./toasterHook";
import localStorage from "localStorage";
import { useAuth } from "../providers/authProvider";

const LoginHook = () => {
  const navigate = useNavigate();
  const { Success, Error } = Toaster();
  const initial = {
    email: "",
    password: "",
  };

  const auth = useAuth();
  const [initialValues, setInitialValues] = useState(initial);
  const [enviando, setEnviando] = useState(false);

  const formSchema = Yup.object().shape({
    email: Yup.string().required("Campo requerido"),
    password: Yup.string().required("Campo requerido"),
  });

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  const submitForm = async (values) => {
    const correo = base64.encode(values.email);
    await auth.logIn(correo)
      .then((resp) => {
        const data = resp.data[0];
        if (data.x_studio_contrasena_app === values.password) {
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("user", JSON.stringify(data));
          auth.setUser(data);
          Success("¡Acceso correcto!");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          Error("Contraseña incorrecta, intenta de nuevo");
        }
      })
      .catch((err) => {
        Error("Error al intentar hacer login");
      })
      .finally(() => {
        setEnviando(false);
      });
  };

  return {
    submitForm,
    initialValues,
    enviando,
    setEnviando,
    formSchema,
  };
};

export default LoginHook;
