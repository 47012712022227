import axiosInstance from "./axiosInstance";

const ProjectService = {
    getAllProjects: (id) => {
        return axiosInstance.get(`projectsf.php?p=${id}`);
    },
    getActividadDelDia: (contrato, tiempo) => {
        return axiosInstance.get(`actdiaf.php?p=${contrato}&o=${tiempo}`);
    },
    getPareto: (contrato, tiempo) => {
        return axiosInstance.get(`actdiafr.php?p=${contrato}&o=${tiempo}`);
    },
    getTop: (contrato, tiempo) => {
        return axiosInstance.get(`actdiafd.php?p=${contrato}&o=${tiempo}`);
    },
    getTrending: (contrato, tiempo, tipo) => {
        return axiosInstance.get(`actdiaft.php?p=${contrato}&o=${tiempo}&f=${tipo}`);
    },
}

export default ProjectService;