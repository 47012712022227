import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { clearState } from "../redux/ProjectSlice";
import { Header } from "../components/contrato/header";
import { Counter } from "../components/contrato/counter";
import { Partes } from "../components/contrato/partes";
import { DisplayPartes } from "../components/contrato/displayPartes";
import { Pareto } from "../components/contrato/pareto";
import { Top } from "../components/contrato/top";
import { Trending } from "../components/contrato/trending";
import { useParams } from "react-router-dom";

export default function Contrato() {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(clearState());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch]);

  return (
    <>
      <div className="mt-6 w-full flex justify-center">
        <div className="w-11/12 flex flex-grid">
          <Header />
        </div>
      </div>
      <div className="mt-2 w-full flex justify-center">
        <div className="w-11/12 flex flex-grid">{<Counter />}</div>
      </div>
      <div className="mt-4 w-full flex justify-center">
        <div className="w-11/12 flex flex-grid space-x-4">
          <div className="w-2/12">{<Partes />}</div>
          <div className="w-10/12 space-y-4">
            <div className="w-full">{<DisplayPartes />}</div>
            <div className="flex flex-grid space-x-4">
              <div className="w-8/12">{<Pareto />}</div>
              <div className="w-4/12">{<Top />}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-6 w-full flex justify-center">
        <div className="w-11/12 flex flex-grid">
          <div className="w-full">{<Trending />}</div>
        </div>
      </div>
    </>
  );
}
