import { apiExcel } from "../services/axiosInstance";
import axios from "axios";
import axiosInstance from "./axiosInstance";

const ExportService = {
  getDataForPDF: ({ contrato, inicio, fin }) => {
    return axiosInstance.get(
      `actdiarPDF.php?p=${contrato}&fi=${inicio}&ff=${fin}`
    );
  },
  // downloadPDF: (data) => {
  //     return axiosInstance.post(`PDFServer/PDF.php`, data, { responseType: 'blob' });
  // },
  downloadPDF: (data) => {
    return axios.post(`/api/generatedPdf`, data, { responseType: "blob" });
  },
  getDataForXLSX: ({ contrato, inicio, fin }) => {
    return axiosInstance.get(
      `actdiar.php?p=${contrato}&fi=${inicio}&ff=${fin}`
    );
  },
  downloadXLSX: ({ contrato, inicio, fin, partes, semana, empresa }) => {
    return apiExcel.get(
      `xlsxp.php?p=${contrato}&fi=${inicio}&ff=${fin}&partes=${partes}&w=${semana}&b=${empresa}`,
      { responseType: "blob" }
    );
  },
};

export default ExportService;
