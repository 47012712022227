'use client'
import React from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

const Paginator = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  // Genera los números de página
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-end items-center mt-4">
      {/* Botón de "Anterior" */}
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`p-1 w-[30px] h-[30px] flex items-center justify-center bg-white text-blue-500 border rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white'}`}
      >
        <RiArrowLeftSLine/>
      </button>

      {/* Números de página */}
      {pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={`p-1 w-[30px] h-[30px] flex items-center justify-center border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 hover:bg-blue-500 hover:text-white'}`}
        >
          {number}
        </button>
      ))}

      {/* Botón de "Siguiente" */}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`p-1 w-[30px] h-[30px] flex items-center justify-center bg-white text-blue-500 border rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white'}`}
      >
        <RiArrowRightSLine />
      </button>
    </div>
  );
};

export default Paginator;
