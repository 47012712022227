import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contrato: '',
    empresa: '',
    partes: [],
    partes_seleccionadas: 'all',
    tiempo: "1", // periodo de tiempo que debe devolver el servicio
}

const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setContrato: (state, action) => {
            state.contrato = action.payload;
        },
        setEmpresa: (state, action) => {
            state.empresa = action.payload;
        },
        setPartes: (state, action) => {
            state.partes = action.payload;
        },
        setParte: (state, action) => {
            state.partes_seleccionadas = action.payload;
        },
        setTiempo: (state, action) => {
            state.tiempo = action.payload;
        },
        setProject: (state, action) => {
            state.contrato = action.payload.contrato;
            state.empresa = action.payload.empresa;
            state.partes = action.payload.partes;
        },
        clearState: (state) => {
            state.partes = [];
            state.partes_seleccionadas =  'all';
            state.tiempo = "1";
        }
    }
})

export const { setContrato, setEmpresa, setPartes, setParte, setTiempo, setProject, clearState } = projectSlice.actions;
export default projectSlice.reducer;