import { useState } from "react";
import pareto from "highcharts/modules/pareto";
import Highcharts from "highcharts";
import Accessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";

if (typeof Highcharts === "object") {
  pareto(Highcharts);
  Accessibility(Highcharts);
}


const Chart = ({ options }) => {
  const [chartOptions, setChartOptions] = useState(options);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default Chart;
