import React from "react";
import useLanguage from "../hooks/I18nHook";
import HomeHook from "../hooks/homeHook";
import { Datatable } from "../components/Datatable";
import Welcome from "../components/welcome";

export default function Index() {
  const { translate } = useLanguage();
  const { data } = HomeHook();

  const columns = [
    { field: "contrato", headerName: translate("noContract") },
    { field: "cliente", headerName: translate("customer") },
    { field: "inicio", headerName: translate("startDate") },
    { field: "fin", headerName: translate("endDate") },
    { field: "actualizado", headerName: translate("lastUpdate") },
  ];

  return (
    <>
      <div className="mt-6 w-full flex justify-center">
        <div className="w-10/12 flex flex-grid space-x-4">
          <div className="w-2/12">
            <Welcome contratos={data.length} />
          </div>
          <div className="w-10/12">
            {data.length === 0 && (
              <Datatable columns={columns} actions={true} data={[]} />
            )}
            {data.length > 0 && (
              <Datatable columns={columns} actions={true} data={data} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
