import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Pareto from "highcharts/modules/pareto";

Pareto(Highcharts);

const GeneratePDF = ({ data }) => {
  const {
    titulo,
    fecha_inicio,
    fecha_fin,
    contrato,
    empresa,
    inspeccionadas,
    aceptadas,
    rechazadas,
    partes,
    grafica_pareto,
    grafica_defectos,
    grafica_trending,
  } = data;

  // Ordenar datos de Pareto
  debugger;
  const sortedPareto = Object.entries(grafica_pareto.cantidades).sort(
    (a, b) => b[1] - a[1]
  );
  const paretoCategories = sortedPareto.map(([key]) => key);
  const paretoValues = sortedPareto.map(([, value]) => value);

  const cantidadTop = Object.entries(grafica_defectos.defectos);
  const trendingCategories = Object.keys(grafica_trending.data);
  const trendingValues = Object.values(grafica_trending.data);

  useEffect(() => {
    renderCharts();
  }, []);

  // Configuración de gráficos
  const renderCharts = () => {
    Highcharts.chart("paretoChart", {
      chart: { type: "column" },
      title: { text: "" },
      xAxis: { categories: paretoCategories },
      yAxis: [
        { title: { text: "Cantidad" } },
        { title: { text: "Porcentaje" } },
      ],
      series: [
        { name: "Cantidad", data: paretoValues, type: "column" },
        {
          name: "Porcentaje",
          data: paretoValues.map((val) => (val / inspeccionadas) * 100),
          type: "pareto",
        },
      ],
    });

    Highcharts.chart("defectosChart", {
      chart: { type: "pie" },
      title: { text: "" },
      series: [{ data: cantidadTop }],
    });

    Highcharts.chart("trendingChart", {
      chart: { type: "line" },
      xAxis: { categories: trendingCategories },
      series: [{ name: "Defectos", data: trendingValues }],
    });
  };

  return (
    <div id="chart" className="p-4" style={{ width: "900px" }}>
      <div>
        <div className="flex justify-between border p-2">
          <div>{`REPORTE ${titulo}`}</div>
          <div>{`${fecha_inicio} al ${fecha_fin}`}</div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-4">
          <div className="text-center">
            Total Inspeccionadas: {inspeccionadas}
          </div>
          <div className="text-center">Total Rechazadas: {rechazadas}</div>
          <div className="text-center">Total Aceptadas: {aceptadas}</div>
        </div>
        <div className="mt-4">
          <div id="paretoChart" className="h-64"></div>
          <div id="defectosChart" className="h-64 mt-4"></div>
          <div id="trendingChart" className="h-64 mt-4"></div>
        </div>
      </div>
    </div>
  );
};

export default GeneratePDF;
