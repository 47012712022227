import { Link } from "react-router-dom";
import {
  RiArrowLeftLine,
  RiFileExcel2Fill,
  RiFilePdf2Fill,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Divider from "./divider";
import { useEffect, useState } from "react";
import { setTiempo } from "../../redux/ProjectSlice";
import { Modal } from "../modals/modal";
import { ModalExportacion } from "../modals/modalExportacion";
import base64 from "base-64";
import ExportService from "../../services/exportService";
import { Downloader } from "../downloader";
import useLanguage from "../../hooks/I18nHook";
import Toaster from "../../hooks/toasterHook";
import GeneratePDF from "../../pages/generatePDF";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const Header = () => {
  const { translate } = useLanguage();

  const { Success, Error } = Toaster();
  const dispatch = useDispatch();

  const { empresa, contrato } = useSelector((state) => state.projects);
  const numContrato = base64.encode(contrato);

  const [modalType, setModalType] = useState(0); // 0: Ninguno, 1: PDF, 2: Excel
  const [openModal, setOpenModal] = useState(false);
  const [tiempoOption, setTiempoOption] = useState("1");
  const [downloading, setDownloading] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState();
  const tiempos = [
    { value: "0", text: translate("today") },
    { value: "1", text: translate("last7") },
    { value: "2", text: translate("last15") },
    { value: "3", text: translate("last30") },
    { value: "4", text: translate("last3M") },
  ];

  useEffect(() => {
    dispatch(setTiempo(tiempoOption));
  }, [tiempoOption]);

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("chart");
      if (element) {
        var fileName = getFileName("pdf");
        html2canvas(element).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF();
          pdf.addImage(imgData, "PNG", 10, 10, 190, 190);
          pdf.save(fileName);
          setMessage("");
          setDownloading(false);
        });
      }
    }, 1500);
  }, [data?.id]);

  const exportPDF = (data, intervalo) => {
    setMessage("Consultando información");
    setDownloading(true);
    let params = {};
    let body = {};
    let titulo = "";
    if (intervalo === "2") {
      // Por Semana
      params = getDateFromWeek(data.year, data.week);
      titulo = `SEMANA ${data.week} DEL AÑO ${data.year}`;
    } else {
      // Por Rango
      params = {
        contrato: numContrato,
        inicio: data.startDate,
        fin: data.endDate,
      };
      titulo = `DE PERIODO DE FECHA`;
    }

    const uuidv4 = () => {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          +c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
        ).toString(16)
      );
    };

    ExportService.getDataForPDF(params)
      .then((resp) => {
        setMessage("Espera un momento, estamos generando tu archivo");
        const data = resp.data;
        data.titulo = titulo;
        data.fecha_inicio = params.inicio;
        data.fecha_fin = params.fin;
        data.contrato = contrato;
        data.empresa = empresa;
        data.id = uuidv4();
        setData(resp.data);
      })
      .catch((err) => {
        setDownloading(false);
        Error("Error al consultar la información para generar el PDF");
        console.error(err);
      });
  };

  const exportExcel = async (data, intervalo) => {
    setMessage("Consultando información");
    setDownloading(true);
    let params = {};
    let titleWeek = "";
    if (intervalo === "2") {
      // Por Semana
      params = getDateFromWeek(data.year, data.week);
      titleWeek = `S${data.week}-${data.year}`;
    } else {
      // Por Rango
      params = {
        contrato: numContrato,
        inicio: data.startDate,
        fin: data.endDate,
      };
      titleWeek = getWeekString(data.startDate, data.endDate);
    }

    params.semana = titleWeek;
    params.empresa = base64.encode(empresa);

    await ExportService.getDataForXLSX(params)
      .then((resp) => {
        setMessage("Espera un momento, estamos generando tu archivo");
        downloadXLSX(params);
      })
      .catch((err) => {
        setDownloading(false);
        Error("Error al consultar la información para generar el Excel");
        console.error(err);
      });
  };

  const downloadXLSX = async (data) => {
    const fileName = getFileName("xlsx");

    let partes = [];
    partes.push("0"); // Para que muestre todas las partes de momento
    partes = JSON.stringify(partes);
    partes = base64.encode(partes);

    data.partes = partes;
    await ExportService.downloadXLSX(data)
      .then((resp) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        Error("Error al descargar el archivo");
        console.error(err);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const getFileName = (ext) => {
    const date = new Date();
    const year = date.getFullYear().toString().slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const fileName = `REPORTE_DEFECTOS_${year}${month}${day}${hours}${minutes}${seconds}.${ext.toLowerCase()}`;
    return fileName;
  };

  const getDateFromWeek = (year, week) => {
    const numWeek = 1 + (parseInt(week) - 1) * 7;
    let dateStart = new Date(year, 0, numWeek);
    dateStart = dateStart.toISOString().split("T")[0];
    let dateEnd = new Date(year, 0, numWeek + 6);
    dateEnd = dateEnd.toISOString().split("T")[0];
    return { contrato: numContrato, inicio: dateStart, fin: dateEnd };
  };

  const getWeekString = (init, end) => {
    const initWeek = getWeekNumber(init);
    const endWeek = getWeekNumber(end);

    init = new Date(init.replace("-", "/"));
    end = new Date(end.replace("-", "/"));

    const week =
      initWeek === endWeek
        ? `S${initWeek}-${init.getFullYear()}`
        : `S${initWeek}-${init.getFullYear()} - S${endWeek}-${end.getFullYear()}`;

    return week;
  };

  const getWeekNumber = (date) => {
    const tmpDate = new Date(date.replace("-", "/"));
    const firstDay = new Date(tmpDate.getFullYear(), 0, 1); // Obtenemos el primer día del año
    const days = Math.floor(tmpDate - firstDay) / (24 * 60 * 60 * 1000); // Diferencia en días
    const weekNumber = Math.ceil((days + firstDay.getDay() + 1) / 7); // Calcular número de semana
    return weekNumber;
  };

  return (
    <>
      {downloading && <Downloader message={message} />}
      {openModal && (
        <Modal
          title={modalType === 1 ? "Exportar PDF" : "Exportar Excel"}
          setOpenModal={setOpenModal}
          width={"600"}
        >
          {modalType === 1 && (
            <>
              <ModalExportacion
                handleClick={exportPDF}
                setOpenModal={setOpenModal}
              />
            </>
          )}
          {modalType === 2 && (
            <>
              <ModalExportacion
                handleClick={exportExcel}
                setOpenModal={setOpenModal}
              />
            </>
          )}
        </Modal>
      )}
      <div className="bg-white w-full min-h-[120px] p-4 rounded">
        <div className="flex flex-grid items-center justify-between">
          <div className="flex flex-grid space-x-8">
            <div className="flex flex-col">
              <span className="text-xs text-gray-500">
                {translate("contract")}
              </span>
              <span className="text-2xl font-bold">{contrato}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs text-gray-500">
                {translate("enterprise")}
              </span>
              <span className="text-2xl font-bold">{empresa}</span>
            </div>
          </div>
          <div>
            <Link
              to={"/"}
              className="ring-1 ring-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white px-2 py-1 rounded text-sm flex flex-grid items-center transition-colors"
            >
              <RiArrowLeftLine className="mr-1" />
              {translate("goBack")}
            </Link>
          </div>
        </div>
        <Divider />
        <div className="flex flex-grid items-center justify-between">
          <div>
            <select
              name="tiempo"
              value={tiempoOption}
              id="tiempo"
              onChange={(event) => {
                setTiempoOption(event.target.value);
              }}
              className="border-2 px-2 py-1"
            >
              {tiempos.map((item) => {
                return (
                  <option key={`tiempo_${item.value}`} value={item.value}>
                    {item.text}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="flex flex-grid space-x-4 mt-2">
            <button
              className="flex flex-grid items-center px-2 py-1 rounded bg-gray-700 text-white text-xs hover:bg-red-500 transition-colors"
              onClick={(event) => {
                setModalType(1);
                setOpenModal(true);
              }}
            >
              <RiFilePdf2Fill className="mr-1" /> {translate("exportPDF")}
            </button>
            <button
              className="flex flex-grid items-center px-2 py-1 rounded bg-gray-700 text-white text-xs hover:bg-green-600 transition-colors"
              onClick={(event) => {
                setModalType(2);
                setOpenModal(true);
              }}
            >
              <RiFileExcel2Fill className="mr-1" /> {translate("exportExcel")}
            </button>
          </div>
        </div>
        {data && downloading && <GeneratePDF data={data} />}
      </div>
    </>
  );
};
