import { useDispatch, useSelector } from "react-redux";
import Divider from "../Divider";
import {  useState } from "react";
import { RiLightbulbFill } from "react-icons/ri";
import { setParte } from "../../redux/ProjectSlice";

export const Partes = () => {
    const dispatch = useDispatch();
    const { tiempo, partes, partes_seleccionadas } = useSelector((state) => state.projects);
    const [loading, setLoading] = useState(false);

    return (
        <>
            <div className="bg-white w-full min-h-[510px] p-4 rounded">
                <div className="flex flex-col">
                    <div className="mb-2">
                        <input type="text" className="ring-2 ring-slate-200 rounded w-full px-2 py-1" placeholder="Buscar parte..."/>
                    </div>
                    <Divider />
                    <div className="grid grid-cols-1 gap-3 mt-2">
                        {
                            loading &&
                            <div className="flex flex-col w-full items-center">
                                <span>Cargando partes...</span>
                            </div>
                        }
                        {
                            !loading &&
                            <>
                                {
                                    partes?.map((item, idx) => {
                                        return <button key={`parte_${idx}`} className={`${item.id === partes_seleccionadas ? 'bg-slate-200' : ''} flex w-full items-center hover:bg-slate-200 px-2 py-1 rounded group`} onClick={(e) => {
                                            dispatch(setParte(item.id));
                                        }}>
                                            <span className={`${item.id === partes_seleccionadas ? 'bg-blue-500 text-white' : 'bg-slate-200 text-slate-600'} rounded-full p-1  size-7 mr-2 flex items-center justify-center group-hover:bg-blue-500 group-hover:text-white transition-colors`}>
                                                <RiLightbulbFill />
                                            </span>
                                            <span className={`${item.id === partes_seleccionadas ? 'text-blue-500' : ''} group-hover:text-blue-500 font-bold transition-colors`}>
                                                {item.parte}
                                            </span>
                                        </button>
                                    })
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}