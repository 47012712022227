import * as es from '../locales/es.json';
import * as en from '../locales/en.json';

const useLanguage = () => {
    const translate = (definition) => {
        const locale = localStorage.getItem('lang') || 'es';
        if(locale === 'es') return es[definition];
        else return en[definition];
    }

    return {
        translate
    }
}
export default useLanguage;