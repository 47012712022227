import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const DisplayPartes = () => {
    const { partes_seleccionadas, partes } = useSelector((state) => state.projects);
    const [parteDisplayed, setParteDispayed] = useState("");

    useEffect(() => {
        if(partes?.length > 0){
            const parte = partes.find(x => x.id === partes_seleccionadas)?.parte;
            setParteDispayed(parte);
        }
    }, [partes, partes_seleccionadas]);
    

    return (
        <>
            <div className="bg-white w-full flex items-center min-h-[40px] h-auto p-4 rounded">
                <div className="flex flex-grid items-center">
                    <div className="text-sm">
                        No. de Parte: <span className="text-sky-600 font-bold">{ parteDisplayed }</span>
                    </div>
                </div>
            </div>
        </>
    );
}