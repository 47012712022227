'use client'

import { useSelector } from "react-redux";
import Chart from "../highcharts/chart";
import { useEffect, useState } from "react";
import ProjectService from "../../services/projectService";
import base64 from 'base-64';
import { RiCheckboxCircleLine, RiLoader5Line } from "react-icons/ri";

export const Top = () => {
    const { tiempo, contrato } = useSelector((state) => state.projects);
    const [sortedParts, setSortedParts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getTopDefectos();
    }, [, tiempo]);

    const getTopDefectos = async () => {
        setLoading(true);
        let numContrato = base64.encode(contrato);
        await ProjectService.getTop(numContrato, tiempo)
        .then(resp => {
            setSortedParts(Object.entries(resp.data.defectos))
        })
        .catch(err => {

        })
        .finally(() => {
            setLoading(false);
        });

    }

    const options = {
        chart: {
            type: 'pie',
            width: null,
            spacingBottom: 20, 
            spacingTop: 20,  
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        series: [{
            name: 'Piezas en defecto',
            data: sortedParts
        }]
    };

    return (
        <>
            <div className="bg-white w-full min-h-[440px] h-auto p-4 rounded space-y-2">
                <div className="w-full">
                    <div className="w-full">
                        <span className="text-sm text-gray-400">Top 5 defectos</span>
                    </div>
                </div>
                <div className="w-full min-h-[360px] max-h-[360px] flex items-center justify-center overflow-hidden">
                    {
                        loading &&
                        <div className="flex flex-col w-full items-center">
                            <RiLoader5Line className="animate-spin text-3xl text-blue-500" />
                            <span>Cargando Gráfica...</span>
                        </div>
                    }
                    {
                        (!loading && sortedParts.length === 0) &&
                        <div className="flex flex-col w-full items-center text-center">
                            <RiCheckboxCircleLine className="text-3xl text-green-500" />
                            <span>No hay datos</span>
                        </div>
                    }
                    {
                        (!loading && sortedParts.length > 0) &&
                        <div className="w-full h-full">
                            <Chart options={options} />
                        </div>
                    }
                </div>
            </div>
        </>
    );
}