import { RiDownloadCloud2Line } from "react-icons/ri";


export const Downloader = ({ message }) => {

    return (
        <>
            <div className="bg-neutral-500-custom fixed inset-0 flex items-center justify-center z-50">
                <div className="text-center w-6/12 flex flex-col items-center justify-center">
                    <div className="w-full flex justify-center">
                        <RiDownloadCloud2Line className="animate-bounce text-4xl text-blue-600"/>
                    </div>
                    <div className="w-full flex justify-center">
                        <span className="text-white mt-2">{message}</span>
                    </div>
                </div>
            </div>
        </>
    );
}