import { Link } from "react-router-dom";
import { Fragment, useMemo, useState } from "react";
import Paginator from "./Paginador";
import { useDispatch } from "react-redux";
import useLanguage from "../hooks/I18nHook";
import { setProject } from "../redux/ProjectSlice";

export const Datatable = ({ columns = [], url, data }) => {
  const { translate } = useLanguage();
  const dispatch = useDispatch();

  const [objRows, setObjRows] = useState(data || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const months = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };

  const handleGetPropertyValue = (objectRow, field) => {
    if (objectRow === null) {
      return null;
    }

    let value = objectRow[field];
    if (field === "contrato") {
      value = (
        <Link
          to={`/contrato/${objectRow.id}`}
          onClick={(event) => {
            dispatch(
              setProject({
                contrato: objectRow.contrato,
                empresa: objectRow.cliente,
                partes: null,
              })
            );
          }}
          className="text-sky-500 hover:text-sky-600 transition-colors"
          prefetch='true'
        >
          {objectRow[field]}
        </Link>
      );
    }

    if (field === "inicio" || field === "fin") {
      value = (
        <span className="text-xs bg-blue-500 text-white px-2 py-1 rounded">
          {objectRow[field]}
        </span>
      );
    }

    if (field === "actualizado") {
      value = <span>{getFormat(objectRow[field])}</span>;
    }

    return value;
  };

  const getFormat = (date) => {
    const dateWOFormat = date.split(" ");
    const fechaTmp = dateWOFormat[0].split("-").reverse();
    const horaTmp = dateWOFormat[1];

    const stringDate =
      fechaTmp[0] + " " + months[Number(fechaTmp[1])] + " " + fechaTmp[2];
    const stringHour = horaTmp + " Hrs.";

    return stringDate + " " + stringHour;
  };

  const filteredData = useMemo(() => {
    return objRows.filter((item) =>
      Object.values(item).some((val) =>
        val.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return; // Evita cambiar a una página no válida
    setCurrentPage(page);
  };

  return (
    <>
      <div className="bg-white w-full min-h-[200px] rounded p-4 space-y-4">
        <div className="p-4 text-sm">
          <span>{translate("myContracts")}</span>
        </div>
        <div className="flex justify-end">
          <div>
            <input
              type="search"
              className="border-2 rounded px-3 py-1"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="text-sm">
          <table className="w-full p-2 border border-2 rounded">
            <thead className="p-3">
              <tr className="p-2">
                {columns.map((column, idx) => {
                  return (
                    <th
                      key={`th_${column.field}_${idx}`}
                      className="text-black text-left text-sm px-3 py-2 border-b-2"
                    >
                      {column.headerName}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Object.entries(currentData).length > 0 &&
                currentData.map((row, idx) => {
                  return (
                    <Fragment key={`row_${idx}`}>
                      <tr key={`row_${idx}`} className="hover:bg-gray-100 px-1">
                        {columns.map((column, index) => {
                          return (
                            <td
                              key={`column_${idx}_${index}`}
                              className="px-3 py-2 border-b-2 text-sm"
                            >
                              {handleGetPropertyValue(row, column.field)}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
              {Object.entries(currentData).length === 0 && (
                <tr>
                  <td colSpan={"6"} className="text-center text-gray-500 py-2">
                    {translate("noRows")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mb-4">
          <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};
