import { useState, useEffect } from 'react';

const AnimatedCounter = ({ targetNumber, duration = 2000 }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        const end = parseInt(targetNumber, 10); // Asegúrate de que el número final sea un entero)
        if (start === end) return; // Si el número inicial y final son iguales, no hacer nada

        let startTime = null;

        const step = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const progress = Math.min((timestamp - startTime) / duration, 1);
            setCount(Math.floor(progress * (end - start) + start));

            if (progress < 1) {
                requestAnimationFrame(step);
            }
        };

        requestAnimationFrame(step);
    }, [targetNumber, duration]);

    return (
        <div className="text-4xl font-bold">
            {count}
        </div>
    );
};

export default AnimatedCounter;
