'use client'

import { useSelector } from "react-redux";
import Chart from "../highcharts/chart";
import { useEffect, useState } from "react";
import ProjectService from "../../services/projectService";
import base64 from 'base-64';
import { RiCheckboxCircleLine, RiLoader5Line } from "react-icons/ri";

export const Pareto = () => {
    const { tiempo, contrato } = useSelector((state) => state.projects);
    const [categorias, setCategorias] = useState([]);
    const [cantidades, setCantidades] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDataPareto();
    }, [, tiempo]);

    const getDataPareto = async () => {
        setLoading(true)
        let numContrato = base64.encode(contrato);
        await ProjectService.getPareto(numContrato, tiempo)
            .then(resp => {
                setCategorias(Object.keys(resp.data.cantidades));
                setCantidades(Object.values(resp.data.cantidades));
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
            });
    }

    const options = {
        chart: {
            zoomType: "xy",
            width: null,
            spacingBottom: 20, 
            spacingTop: 20, 
        },
        title: '',
        subtitle: '',
        xAxis: [
            {
                categories: categorias,
                crosshair: true,
            },
        ],
        yAxis: [
            {
                // Primary yAxis for Percentage
                labels: {
                    format: "{value} %",
                    style: {
                        color: "#000000",
                    },
                },
                title: {
                    text: "Porcentaje",
                    style: {
                        color: "#000000",
                    },
                },
                opposite: true,
                max: 100,
            },
            {
                // Secondary yAxis for Defects
                title: {
                    text: "Piezas rechazadas",
                    style: {
                        color: "#7cb5ec",
                    },
                },
                labels: {
                    format: "{value}",
                    style: {
                        color: "#7cb5ec",
                    },
                },
            },
        ],
        tooltip: {
            shared: true,
        },
        legend: {
            align: "left",
            x: 40,
            verticalAlign: "top",
            y: 0,
            backgroundColor: "rgba(255,255,255,0.25)",
        },
        series: [
            {
                name: "Defectos",
                type: "column",
                yAxis: 1,
                data: cantidades,
                color: '#7cb5ec'
            },
            {
                name: "Porcentaje",
                type: "pareto",
                yAxis: 0,
                zIndex: 10,
                baseSeries: 0,  // Apunta al índice de la serie de defectos
                color: '#000000',
                tooltip: {
                    valueSuffix: ' %',
                    valueDecimals: 2,
                }
            },
        ],
    };


    return (
        <>
            <div className="bg-white w-full min-h-[440px] h-auto p-4 rounded space-y-2">
                <div className="w-full">
                    <div className="w-full">
                        <span className="text-sm text-gray-400">Gráfica de pareto</span>
                    </div>
                </div>
                <div className="w-full min-h-[360px] max-h-[360px] flex items-center justify-center">
                    {
                        loading &&
                        <div className="flex flex-col w-full items-center">
                            <RiLoader5Line className="animate-spin text-3xl text-blue-500" />
                            <span>Cargando Gráfica...</span>
                        </div>
                    }
                    {
                        (!loading && categorias.length === 0) &&
                        <div className="flex flex-col w-full items-center text-center">
                            <RiCheckboxCircleLine className="text-3xl text-green-500" />
                            <span>No hay datos</span>
                        </div>
                    }
                    {
                        (!loading && categorias.length > 0) &&
                        <Chart options={options} />
                    }
                </div>
            </div>
        </>
    );
}