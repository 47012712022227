import { RiUserLine } from "react-icons/ri";
import useLanguage from "../hooks/I18nHook";
import { useAuth } from "../providers/authProvider";
import Divider from "./Divider";

export default function Welcome({ contratos = 0 }) {
  const { translate } = useLanguage();
  const auth = useAuth();
  const username = auth?.user?.name;

  return (
    <>
      <div className="bg-white w-full min-h-[150px] rounded">
        <div className="px-1 py-4 flex items-center justify-center space-x-2">
          <div className="text-4xl">
            <RiUserLine />
          </div>
          <div className="text-sm">
            ¡{translate("hi")} <span className="font-bold">{username}</span>!
          </div>
        </div>
        <Divider />
        <div className="px-4 flex justify-center mt-4">
          <div className="text-sm">
            {translate("have")} <span className="font-bold">{contratos}</span>{" "}
            {translate("activeContracts")}
          </div>
        </div>
      </div>
    </>
  );
}
