import { configureStore, combineReducers } from '@reduxjs/toolkit';
import ProjectSlice from './ProjectSlice';

const rootReducer = combineReducers({
    projects: ProjectSlice
});

const Store = configureStore({
    reducer: rootReducer,
})

export default Store;