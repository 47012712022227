"use client"

import { Formik, Field, ErrorMessage  } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';

const SchemaRango = Yup.object().shape({
    startDate: Yup.string().required('El campo fecha inicio es requerido'),
    endDate: Yup.string().required('El campo fecha fin es requerido')
});

const SchemaSemanal = Yup.object().shape({
    year: Yup.string().required('El campo año es requerido'),
    week: Yup.number()
    .required('El campo semana es requerido')
    .min(1, 'La semana debe ser al menos 1')
    .max(52, 'La semana no puede ser mayor a 52')
});


export const ModalExportacion = ({ handleClick, setOpenModal }) => {
    const { refFormik } = useRef();

    const initial = {
        startDate: '',
        endDate: '',
        year: '',
        week: ''
    };

    useEffect(() => {
        let listYears = [];
        let today = new Date();
        let initialYear = today.getFullYear();
        let finalYear = 2015;

        listYears.push({ value: '', text: '-- Elige un año --' });
        for (let i = initialYear; i >= finalYear; i--) {
            listYears.push({ value: i.toString(), text: i});
        }

        setYears(listYears);
    }, []);

    const intervalosExportacion = [
        { value: "1", text: 'Rango de fecha' },
        { value: "2", text: 'Semanal' }
    ];

    const [years, setYears] = useState([]);
    const [initialValues, setInitialValues] = useState(initial);
    const [intervalo, setIntervalo] = useState("1");
    const validationSchema = intervalo === "1" ? SchemaRango : SchemaSemanal;

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ isValid, dirty, values, resetForm, setFieldValue, errors, handleBlur }) => (
                    <>
                        <div className="w-full space-y-8">
                            <div className="w-full flex">
                                <div>
                                    <label htmlFor="formType">Tipo de exportación: </label>
                                    <Field as="select" name="formType" className="border-2 text-sm px-1 py-0" onChange={(e) => {
                                        setIntervalo(e.target.value);
                                        resetForm({ values: initial });
                                        setFieldValue('formType', e.target.value);
                                    }}>
                                        {
                                            intervalosExportacion.map(item => {
                                                return <option key={`intervalo_${item.value}`} value={item.value}>{item.text}</option>
                                            })
                                        }
                                    </Field>
                                </div>
                            </div>
                            <div className="mt-2 space-y-4">
                                <div className="flex flex-grid w-full">
                                    {
                                        intervalo === '1' &&
                                        <>
                                            <div className="flex flex-col w-6/12">
                                                <label htmlFor="startDate" className="text-gray-400 text-sm mb-1">Fecha inicio:</label>
                                                <Field name="startDate" className="text-black p-1 ring-slate-300 active:ring-slate-400 ring-1 mx-1 rounded-sm" type="date" />
                                                <ErrorMessage name="startDate" component="div" className="text-red-600 text-sm mt-1" />
                                            </div>
                                            <div className="flex flex-col w-6/12">
                                                <label htmlFor="endDate" className="text-gray-400 text-sm mb-1">Fecha fin:</label>
                                                <Field name="endDate" className="text-black p-1 ring-slate-300 active:ring-slate-400 ring-1 mx-1 rounded-sm" type="date" />
                                                <ErrorMessage name="endDate" component="div" className="text-red-600 text-sm mt-1" />
                                            </div>
                                        </>
                                    }
                                    {
                                        intervalo === '2' &&
                                        <>
                                            <div className="flex flex-col w-6/12">
                                                <label htmlFor="year">Año: </label>
                                                <Field as="select" name="year" className="border-2 text-sm px-1 py-1" onChange={(e) => {
                                                    setFieldValue('year', e.target.value);
                                                }}>
                                                    {
                                                        years.map(item => {
                                                            return <option key={`year_${item.value}`} value={item.value}>{item.text}</option>
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="year" component="div" className="text-red-600 text-sm mt-1" />
                                            </div>
                                            <div className="flex flex-col w-6/12">
                                                <label htmlFor="week" className="text-gray-400 text-sm mb-1">Semana:</label>
                                                <Field 
                                                    name="week" 
                                                    className="text-black p-1 ring-slate-300 active:ring-slate-400 ring-1 mx-1 rounded-sm" 
                                                    min="1" 
                                                    max="52" 
                                                    type="number" 
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        value = value ? parseInt(value, 10) : '';
                                                        setFieldValue('week', value)
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                                <ErrorMessage name="week" component="div" className="text-red-600 text-sm mt-1" />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-grid justify-end space-x-4 mt-4">
                                <button className="border rounded border-gray-600 text-gray-600 px-2 py-0 hover:bg-black hover:text-white transition-colors" onClick={(event) => {
                                    setOpenModal(false);
                                }}>
                                    Cancelar
                                </button>
                                <button 
                                    disabled={!(isValid && dirty)} 
                                    className="disabled:bg-gray-400 disabled:cursor-not-allowed bg-gray-600 hover:bg-black text-white rounded px-2 py-0 transition-colors"
                                    onClick={(e) => {
                                        handleClick(values, intervalo);
                                        setOpenModal(false);
                                    }}
                                >
                                    Exportar
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </>
    );
}