import { useEffect, useState } from "react";
import base64 from 'base-64';
import { useDispatch, useSelector } from "react-redux";
import ProjectService from "../../services/projectService";
import { setPartes } from "../../redux/ProjectSlice";
import AnimatedCounter from "../animatedCounter";
import { RiQuestionFill } from "react-icons/ri";
import { Tooltip } from 'react-tooltip'

export const Counter = () => {
    const dispatch = useDispatch();

    const { tiempo, contrato } = useSelector((state) => state.projects);

    const [pzasInspeccionadas, setPzasInspeccionadas] = useState(0);
    const [pzasRechazadas, setPzasRechazadas] = useState(0);
    const [pzasRetrabajadas, setPzasRetrabajadas] = useState(0);
    const [pzasAceptadas, setPzasAceptadas] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getCounterData();
    }, [tiempo]);

    const getCounterData = async () => {
        setLoading(true);
        let numContrato = base64.encode(contrato);
        await ProjectService.getActividadDelDia(numContrato, tiempo)
            .then(resp => {
                const data = resp.data;
                let tmpPartes = [];
                let inspeccionadas = 0;
                let rechazadas = 0;
                let retrabajadas = 0;
                let aceptadas = 0;


                tmpPartes.push({ id: "all", parte: 'Todos los no. de partes' });
                data.forEach(item => {
                    if (!tmpPartes.some(parte => parte.id === item.x_studio__parte)) tmpPartes.push({
                        id: item.x_studio__parte,
                        parte: item.x_studio__parte
                    })
                    inspeccionadas += Number(item.x_studio_cantidad_inspeccionada);
                    rechazadas += Number(item.x_studio_cantidad_rechazada);
                    retrabajadas += Number(item.x_studio_cantidad_retrabajada);
                    aceptadas += Number(item.x_studio_cantidad_aceptada);
                });

                setPzasInspeccionadas(inspeccionadas);
                setPzasRechazadas(rechazadas);
                setPzasRetrabajadas(retrabajadas);
                setPzasAceptadas(aceptadas);

                dispatch(setPartes(tmpPartes));
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <div className="bg-white w-full min-h-[100px] p-4 rounded">
                <div className="grid grid-cols-4 gap-4">
                    <div className="flex flex-col text-center">
                        <span className="text-xs uppercase mt-1 text-gray-500">Total de piezas inspeccionadas</span>
                        {
                            (loading || pzasInspeccionadas === 0) &&
                            <span className="text-4xl mt-1 font-bold">0</span>
                        }
                        {
                            (!loading && pzasInspeccionadas > 0) &&
                            <AnimatedCounter targetNumber={pzasInspeccionadas} duration={2000} />
                        }

                    </div>
                    <div className="flex flex-col text-center">
                        <span className="text-xs uppercase mt-1 text-gray-500">Total de piezas rechazadas</span>
                        {
                            (loading || pzasRechazadas === 0) &&
                            <span className="text-4xl mt-1 font-bold">0</span>
                        }
                        {
                            (!loading && pzasRechazadas > 0) &&
                            <AnimatedCounter targetNumber={pzasRechazadas} duration={2000} />
                        }
                    </div>
                    <div className="flex flex-col text-center">
                        <span className="text-xs uppercase mt-1 text-gray-500">Total de piezas retrabajadas</span>
                        {
                            (loading || pzasRetrabajadas === 0) &&
                            <span className="text-4xl mt-1 font-bold">0</span>
                        }
                        {
                            (!loading && pzasRetrabajadas > 0) &&
                            <AnimatedCounter targetNumber={pzasRetrabajadas} duration={2000} />
                        }
                    </div>
                    <div className="flex flex-col text-center">
                        <span className="text-xs uppercase mt-1 text-gray-500">Total de piezas aceptadas</span>
                        <span className="flex justify-center">
                            {
                                (loading || pzasAceptadas === 0) &&
                                <span className="text-4xl mt-1 font-bold">0</span>
                            }
                            {
                                (!loading && pzasAceptadas > 0) &&
                                <AnimatedCounter targetNumber={pzasAceptadas} duration={2000} />
                            }
                            <span className="text-sm flex items-start">
                                <RiQuestionFill 
                                    data-tooltip-id="tooltip-formula"
                                    data-tooltip-content="Fórmula: (PZAS RECHAZADAS - PZAS RETRABAJADAS) + PZAS ACEPTADAS = PZAS INSPECCIONADAS"
                                    data-tooltip-place="left"
                                    className="text-blue-500 hover:text-blue-600 transition-colors" />
                            </span>
                            <Tooltip 
                                className="flex items-start"
                                id="tooltip-formula" />
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}