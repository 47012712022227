'use client'

import { useSelector } from "react-redux";
import Chart from "../highcharts/chart";
import { useEffect, useState } from "react";
import ProjectService from "../../services/projectService";
import base64 from 'base-64';
import { RiCheckboxCircleLine, RiLoader5Line } from "react-icons/ri";

export const Trending = () => {
    const opcionesTendencias = [
        { value: 1, text: 'Global' },
        { value: 2, text: 'Por defecto' }
    ];

    const { tiempo, contrato } = useSelector((state) => state.projects);
    const [loading, setLoading] = useState(false);
    const [tipoTendencia, setTipoTendencia] = useState("1");
    const [seriesName, setSeriesName] = useState([]);
    const [seriesData, setSeriesData] = useState([]);

    useEffect(() => {
        getDatatrending();
    }, [, tiempo, tipoTendencia]);

    const getDatatrending = async () => {
        setLoading(true);
        let numContrato = base64.encode(contrato);

        const tendencia = tipoTendencia === "1" ? 'global' : 'defectos'

        await ProjectService.getTrending(numContrato, tiempo, tendencia)
        .then(resp => {
            setSeriesName(Object.values(resp.data.labels));
            if(tipoTendencia === "1")
                globalData(resp.data);
            else
                perDefectsData(resp.data)
        })
        .catch(err => {

        })
        .finally(() => {
            setLoading(false)
        });

    }

    const globalData = async (data) => {
        let points = [];
        let tempData = {
            name: 'Todos los defectos',
            data: []
        };

        tempData.data = Object.entries(data.data);
        tempData.data.sort((a,b) => {
            const dateA = new Date(a[0]);
            const dateB = new Date(b[0]);

            return dateA - dateB;
        });
        points.push(tempData);
        setSeriesData(points);
    }

    const perDefectsData = async (data) => {
        let points = [];
        Object.entries(data.data).forEach(item => {
            let tempData = {
                name: item[0],
                data: []
            };
            tempData.data = Object.entries(item[1]);
            tempData.data.sort((a,b) => {
                const dateA = new Date(a[0]);
                const dateB = new Date(b[0]);
    
                return dateA - dateB;
            });
            points.push(tempData);
        });
        setSeriesData(points);
    }

    const options = {
        chart: {
            type: 'line',
            width: null,
            spacingBottom: 20, 
            spacingTop: 20, 
        },
        title: {
            text: tipoTendencia === '1' ? 'Todos los defectos presentes' : 'Por defecto seleccionado',
            align: 'left'
        },
        yAxis: {
            title: {
                text: 'Número de defectos'
            }
        },
        xAxis: {
            categories: seriesName
        },
        legend: false,
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: true
            }
        },
        series: seriesData,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 900
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    }


    return (
        <>
            <div className="bg-white w-full min-h-[440px] h-auto p-4 rounded space-y-2">
                <div className="w-full mb-6">
                    <div className="w-full">
                        <span className="text-sm text-gray-400">Tendencia tipo:</span>
                        <select name="tiempo" value={tipoTendencia} id="tendencia" onChange={(event) => { setTipoTendencia(event.target.value) }} className="border-2 text-sm ml-4 rounded px-2 py-1">
                            {
                                opcionesTendencias.map(item => {
                                    return <option key={`tendencia_${item.value}`} value={item.value}>{item.text}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="w-full min-h-[400px] max-h-[400px] flex items-center justify-center">
                    {
                        loading &&
                        <div className="flex flex-col w-full items-center">
                            <RiLoader5Line className="animate-spin text-3xl text-blue-500" />
                            <span>Cargando Gráfica...</span>
                        </div>
                    }
                    {
                        (!loading && seriesData.length === 0) &&
                        <div className="flex flex-col w-full items-center text-center">
                            <RiCheckboxCircleLine className="text-3xl text-green-500" />
                            <span>No hay datos</span>
                        </div>
                    }
                    {
                        (!loading && seriesData.length > 0) &&
                        <div className="w-full h-full">
                            <Chart options={options} />
                        </div>
                    }
                </div>
            </div>
        </>
    );
}