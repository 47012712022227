import { Formik, Field } from "formik";
import { RiLoader5Fill, RiLoginCircleLine } from "react-icons/ri";
import { useRef } from "react";
import { ToastContainer } from "react-toastify";
import LoginHook from "../hooks/loginHook";


export default function Login() {
    const {
        submitForm,
        initialValues,
        enviando,
        setEnviando,
        formSchema,
    } = LoginHook();
    const { refFormik } = useRef();

    return (
        <>
            <ToastContainer />
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={formSchema}
            >
                {({ isValid, values, setFieldValue, errors }) => (
                    <>
                        <div className="w-full flex justify-center">
                            <div className="w-4/12 bg-white flex justify-center items-start" style={{height: "calc(100vh - 60px)"}}>
                                <div className="mt-6 space-y-8 w-8/12">
                                    <div className="grid grid-cols-1 gap-4 mb-24">
                                        <div className="flex flex-col">
                                            <img src="/logo-aigx.png" alt="Aigx" width={120} height={60} />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div className="flex flex-col">
                                            <span className="text-xl font-bold">Bienvenido(a)</span>
                                            <span className="text-sm">Por favor, ingresa tus credenciales de acceso</span>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div className="flex flex-col">
                                            <label htmlFor="email" className="text-gray-400 text-sm mb-1">Email:</label>
                                            <Field name="email" className="text-black p-1 ring-slate-300 active:ring-slate-400 ring-1 mx-1 rounded-sm" type="text" placeholder="example@mail.com" />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div className="flex flex-col">
                                            <label htmlFor="password" className="text-gray-400 text-sm mb-1">Contraseña:</label>
                                            <Field name="password" className="text-black p-1 ring-slate-300 active:ring-slate-400 ring-1 mx-1 rounded-sm" type="password" placeholder="********" />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-end mt-6">
                                        <button className="w-full text-sm justify-center disabled:cursor-not-allowed px-2 py-2 bg-blue-400 text-white hover:bg-blue-600 disabled:opacity-25 rounded mx-1 flex flex-grid items-center uppercase" disabled={!isValid} onClick={(event) => {
                                            setEnviando(true);
                                            submitForm(values);
                                        }}>
                                            {
                                                enviando &&
                                                <>
                                                    <RiLoader5Fill className="mr-1 animate-spin" />
                                                    Accediendo...
                                                </>
                                            }
                                            {
                                                !enviando &&
                                                <>
                                                    <RiLoginCircleLine className="mr-1" />
                                                    Acceder
                                                </>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )}
            </Formik>
        </>
    );
}